import { Button, Grid, TableHead } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";

import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import { SxProps, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Order, ScheduleHeader, getComparator, stableSort } from "./utils";
import { useNotify } from "../../util/notify";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

interface iDisplayDateProp {
    date: string;
}
const DisplayDate = ({ date }: iDisplayDateProp) => {

    const d: Date = new Date(Date.parse(date));

    if (!isNaN(Number(d))) {
        let date, month, year;

        date = d.getDate();
        month = d.getMonth() + 1; // take care of the month's number here ⚠️
        year = d.getFullYear();

        return <>{`${date}/${month}/${year}`}</>

    }
    return <></>
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}


const SiteSpend = () => {
    let query = useQuery();
    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [name, setName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { displayNote } = useNotify();
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState('Site');


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const start = query.get("start");
        const end = query.get("end");
        const Go = async () => {
            const response = await doRequest<any>(`reports/sReportSpend/${id}?start=${start}&end=${end}`, 'GET', {})
            console.log(response)
            setData(response);

            if (response.length >= 1) {
                setName(response[0].Site)
            }
        }
        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, [id]);

    const headXS: SxProps = {
        fontWeight: "bold", verticalAlign: "bottom"
    }
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={12}>
                        <h2>Site Spend {name}</h2>



                        {visibleRows.length>0 ? <TableContainer sx={{ maxHeight: "70vh" }}>
                            <Table sx={{ minWidth: 500 }} aria-label="Service S" stickyHeader>
                                <TableHead>

                                    <TableRow>
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="AssetNumber" title="Asset Number" onRequestSort={handleRequestSort} />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Type" title="Appliance" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Make" title="Make" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Model" title="Model" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="SerialNumber" title="Serial Number" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Type" title="Type" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Condition" title="Condition" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="RepairSpend" title="Repair Spend" onRequestSort={handleRequestSort} align="center" />
                                   
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(visibleRows
                                    ).map((row) => {
                                        if (typeof row.Appliance_Background === "string") {
                                            return <TableRow key={row.name} className={row.Appliance_Background} onClick={(ev) => {
                                                ev.preventDefault();
                                                if (row.ApplianceID !== 0) {
                                                    navigate(`/assets/${row.ApplianceID}`)
                                                } else {
                                                    displayNote && displayNote("Asset Discontiuned", "error");
                                                }
                                            }}>
                                                <TableCell component="th" style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>
                                                    {row.AssetNumber}
                                                    <DebugButton data={row} />
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Type}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Make}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Model}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.SerialNumber}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Type}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Condition}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {typeof row.RepairSpend === 'number' && <>  £ {row.RepairSpend.toFixed(2)}</>}
                                                </TableCell>
                                              
                                            </TableRow>
                                        }
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableCell component="th" scope="row" style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>


                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">


                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                        Total
                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                        £ {data.reduce((accumulator, currentValue) => {
                                            return accumulator + currentValue.RepairSpend
                                        }, 0).toFixed(2)}
                                    </TableCell>

                                </TableFooter>
                            </Table>
                        </TableContainer>: <Box>Loading</Box>}
                        
                    </Grid>

                </Grid>
            </GreyBox>
        </Grid>

    </Grid>
}


export default SiteSpend